import React, { MouseEventHandler } from 'react';
import LandingLayout from 'components/ui/compositions/LandingLayout/LandingLayout';
import Editorial from 'components/ui/Editorial';
import Button from 'components/ui/Button';
import useTranslate from 'components/hooks/useTranslate';

export type TamponPageProps = {
  title: string;
  children: React.ReactNode;
  onSkip: MouseEventHandler<any>;
};

function TamponPage({ title, children, onSkip }: TamponPageProps): JSX.Element {
  const t = useTranslate();

  return (
    <LandingLayout
      side="right"
      messages={{
        title,
        baseline: 'makersboard',
        home: '/',
      }}
    >
      <Editorial>{children}</Editorial>
      <p style={{ margin: '2em 0' }}>
        <Button kind="secondary" onClick={onSkip}>
          {t('tampon.continue')}
        </Button>
      </p>
    </LandingLayout>
  );
}

export default TamponPage;
